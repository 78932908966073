import React from 'react';
import { Layout, ComprarSaldoForm } from 'components';

export default function compraSaldo() {
  return (
    <Layout cart={true}>
      <ComprarSaldoForm />
    </Layout>
  );
}
